
::v-deep .el-input__inner {
    border-radius: 15px;
}

::v-deep .el-dialog__header {
    background: #F1F0FE;
    border-radius: 10px 10px 0px 0px;
}

.account-content {
    height: 100%;

    .create-btn {
        padding-bottom: 30px;
    }

    .create-title {
        width: 100px;
        height: 19px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3A3567;
    }

    .res-list {
        min-height: calc(100vh - 380px);
    }

    .tab-content {
        display: flex;
        flex-wrap: wrap;

        .card {
            box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.2);
            width: calc(20% - 25px);
            min-width: 250px;
            height: 260px;
            cursor: pointer;
            margin: 0 20px 20px 0;
            background: #EBEBF9;

            .newContain {
                width: 100%;
                height: 160px;
                border-radius: 4px;
                cursor: pointer;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .checkbox {
                    position: absolute;
                    right: 0;
                    padding: 10px 10px 0 0;

                    ::v-deep .el-checkbox__label {
                        display: none;
                    }
                }
            }

            .mainContain {
                width: 100%;
                height: 160px;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                background: #16113F;
                justify-content: center;

                .pic_box {
                    display: flex;
                    align-items: center;
                }
            }

            .container {

                .title-box {
                    padding: 6px 0 0 14px;
                }

                .left3 {
                    display: flex;
                    justify-content: space-between;
                    padding: 18px 10px 0 10px;

                    ::v-deep .el-button {
                        width: 90px;
                        height: 20px;
                        line-height: 0;
                        background: #594FEE;
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .sort-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .left-sort {
            display: flex;

            .active {
                cursor: pointer;
                color: #574DED;
            }

            .left1 {
                cursor: pointer;
            }

            .left2 {
                padding-left: 30px;
                cursor: pointer;
            }
        }
    }

    .VideoDetail-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        .btn-del-video {
            color: #FB0360;
            cursor: pointer;

            i {
                margin-right: 10px;
                font-size: 14px;
            }
        }
    }
}

