
.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    width: calc(100% - 280px);
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #ffffff;

    .table-box {
        width: 100%;
    }

}
