
.videoBox {
    background: #ffffff;

    ::v-deep.el-tab-pane{
        height: 100%;
    }
    ::v-deep .el-tabs__header {
        padding: 35px 35px 0 35px;
    }

    ::v-deep .el-tabs__item.is-active {
        color: #303133;
    }

    ::v-deep .el-tabs__item {
        font-size: 18px;
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #9ACC5B;
    }

    ::v-deep .el-tabs__active-bar:before {
        position: absolute;
        top: -13px;
        left: 40%;
        content: '';
        width: 0;
        height: 0;
        border-style: dashed dashed solid dashed;
        border-width: 7px;
        border-color: transparent transparent #9ACC5B transparent;
    }

    ::v-deep .el-tabs__content {
        padding: 0 35px;
    }
}
