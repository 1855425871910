
::v-deep .right-sort .el-input__inner {
    border-radius: 15px;
}

::v-deep .el-dialog__body .el-input__inner {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
}

::v-deep .el-dialog {
    border-radius: 10px;
}

::v-deep .el-dialog__footer {
    text-align: center;
}

::v-deep .el-dialog__footer .el-button {
    width: 90px;
    height: 30px;
    line-height: 0;
    border-radius: 15px;
}

::v-deep .el-dialog__footer .el-button--primary {
    background: #594FEE;
}

::v-deep .el-dialog__header {
    background: #F1F0FE;
    border-radius: 10px 10px 0px 0px;
}

::v-deep .el-dialog__title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #161240;
}

.account-content {
    height: 100%;

    .create-btn {
        padding-bottom: 30px;
    }

    .create-title {
        width: 100px;
        height: 19px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3A3567;
    }

    .res-list {
        min-height: 300px;
    }

    .tab-content {
        display: flex;
        flex-wrap: wrap;

        .card {
            box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.2);
            width: calc(20% - 25px);
            min-width: 250px;
            height: 220px;
            cursor: pointer;
            margin: 0 20px 20px 0;
            background: #EBEBF9;

            .newContain {
                width: 100%;
                height: 160px;
                border-radius: 4px;
                cursor: pointer;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .checkbox {
                    position: absolute;
                    right: 0;
                    padding: 10px 10px 0 0;

                    ::v-deep .el-checkbox__label {
                        display: none;
                    }
                }
            }

            .mainContain {
                width: 100%;
                height: 160px;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                background: #16113F;
                justify-content: center;

                .pic_box {
                    display: flex;
                    align-items: center;
                }
            }

            .container {
                padding: 12px;
            }
        }
    }

    .sort-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .left-sort {
            display: flex;

            .active {
                cursor: pointer;
                color: #574DED;
            }

            .left1 {
                cursor: pointer;
            }

            .left2 {
                padding-left: 30px;
                cursor: pointer;
            }
        }
    }

    .mainbox {
        display: flex;
        padding-top: 25px;
        justify-content: space-between;
        cursor: pointer;

        .activeClass {
            width: 344px;
            height: 249px;
            background: #FFFFFF;
            border: 3px solid #9ACC5C;
            border-radius: 10px;
        }

        div {
            width: 344px;
            height: 249px;
            background: #FFFFFF;
            border: 3px solid #BCBACC;
            border-radius: 10px;

            .box1 {
                height: 60px;

                ::v-deep .el-checkbox {
                    float: right;
                    padding: 10px;
                }

                ::v-deep .el-checkbox__inner {
                    width: 51px;
                    height: 51px;
                    background: #9ACC5C;
                    border: 3px solid #9ACC5C;
                    border-radius: 10px;
                }

                ::v-deep .el-checkbox__inner::after {
                    height: 30px;
                    left: 13px;
                    width: 15px;
                    border: 3px solid #FFF;
                    border-left: 0;
                    border-top: 0;
                }
            }

            .box2 {
                height: 80px;
                text-align: center;

                ::v-deep .iconfont {
                    font-size: 80px;
                }
            }

            .box3 {
                text-align: center;
            }
        }
    }
}

